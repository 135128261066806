// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/'
export const PRODUCTS = '/products'
export const CATEGORIES = '/categories'
export const MANUFACTURERS = '/manufacturers'
export const STYLES = '/styles'
export const COLLECTIONS = '/collections'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const ORDERS = '/orders'
export const CUSTOMERS = '/customers'
export const ACCOUNT_MANAGERS = '/account-managers'
export const CUSTOMER_GROUPS = '/customer-groups'
export const COUPONS = '/coupons'
export const COLORS = '/colors'
export const BLOG = '/blog'
export const SETTINGS = '/settings'
export const STAFF_MEMBERS = '/staff-members'
export const WOO_IMPORT = '/woo-import'
export const DISCOUNTS = '/discounts'
export const PRODUCT_POSITION_SCRIPT = '/product-position-script'
export const LANGUAGE_TRANSLATE_SCRIPT = '/language-translate-script'
export const FACEBOOK_FEED_SCRIPT = '/facebook-feed-script'
export const IMAGE_DOWNLOAD_SCRIPT = '/image-download-script'
export const TAGS = '/tags'
export const IMAGE_RESIZE_SCRIPT = '/image-resize-script'
export const IMPORT_SCRIPT = '/import-script'
export const CLEAN_UP_SCRIPT = '/clean-up-script'
export const FABRIC_GROUPS = '/fabric-groups'
// --- Settings pages
export const SITE_SETTINGS = '/site-settings'
export const GENERAL_SETTINGS = '/general-settings'
export const GENERAL_SETTINGS_SHORTER = '/general-settings-shorter'
export const PRIVACY_POLICY_FORM = '/privacy-policy-form'
export const RETURNS_POLICY_FORM = '/returns-policy-form'
export const HOME_PAGE_FORM = '/home-page-form'
export const CONTACT_US_PAGE_FORM = '/contact-us-page-form'
export const ABOUT_US_PAGE_FORM = '/about-us-page-form'
//----
export const COMMERCIAL_OFFERS = '/commercial-offers'
export const CONTENT_BLOCKS = '/content-blocks'
export const REVALIDATE_SCRIPT = '/revalidate-script'
export const DESIGN_SETTINGS = '/design_settings'
export const B2B_SETTINGS = '/b2b-settings'
export const EMBED_CONFIGURATOR_SETTINGS = '/embed-configurator-settings'
export const B2B_CUSTOMERS = '/b2b-customers'

export const POPUP_BANNER_SETTINGS = '/popup-banner-settings'

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '€'

// ---- Constants used to size the react table columnts
export const sm = 599 // in px
export const md = 959 // in px
export const lg = 1280 // in px
export const smallWidth = '60px'
export const midWidth = '90px'
export const largeWidth = '120px'
